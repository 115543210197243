<template>
  <div :class="{
        app_container:true,
        isMobile:isMobileStore.value
    }">
    <NavBarV3
        class="nav_bar_fixed"
        :pageTitle="sectionTitle"
        :hideTitle="hideTitle"
        :isHome="isHome"
        :allowEdit="(!!userInfoStore.info) && ['谭天','王奕帆','王炜文'].includes(userInfoStore.info.name)"
    />
    <slot/>
  </div>
</template>

<script setup lang="ts">
import {useUserInfoStore} from '~/stores/userInfoStore'
const userInfoStore = useUserInfoStore()

import {useIsMobileStore} from "~/stores/isMobileStore" 
import NavBarV3 from '../components/NavBarV3.vue';
const isMobileStore = useIsMobileStore()

const props = defineProps([
    "sectionTitle",
    "isHome",
    "hideTitle",
])

const mounted = ref(process.client)
onMounted(()=>{mounted.value = true})

</script>


<style scoped>
.nav_bar_fixed{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
}
.app_container{
    position: relative;
    min-height: 101vh;
    width: 100%;
    overflow: hidden;
    /* border-bottom: 50px solid rgb(50,50,50); */
}
.app_container:not(.isMobile){
    min-width: 800px;
}
.app_container.isMobile{
    width: calc(100vw);
}
</style>